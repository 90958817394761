.project-screen{
border: 2px solid rgb(243, 242, 242);
border-radius: 10px 10px 0px 0px;
overflow: hidden;
width: 80%;
margin: 20px 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);


max-height: 300px;



}

.p-browser {
  height: 20px;
  background-color: rgb(243, 242, 242);
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;

}

.p-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 3px;
}
.dot-1{
  background-color:  #FC6058;
}
.dot-2{
  background-color:  #FEC02F;
}
.dot-3{
  background-color:  #2ACA3E;
}


.p-img {
  width: 100%;
  transition: all 10s ease;
}


.project-legend{
  h3 {
    text-transform: uppercase;


  }
  a {
    margin-right: 10px;
  }
}