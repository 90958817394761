
form {
  // padding-top: 80px;
  max-width: 500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}


.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: #0a53e4;
//   text-transform: lowercase;

}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);

}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 16px;
  line-height: 1;
  left: 16px;
  text-transform: uppercase;

}

.input-container input {
  height: 50px;
  border-radius: 4px;
  border: none;
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1.2;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;background-color: #E8E9EA;
  text-transform: lowercase;


}
  ::placeholder {
    text-transform: uppercase;


}
.input-container input:focus {
  box-shadow: 0 0 0 2px #79b1ff;
}
