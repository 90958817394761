@import url('https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap');

* {
  box-sizing: border-box;

} 

body {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 18px;
  display: flex;
  margin: auto;
  padding: 20px;
  max-width: 700px;
  line-height: 1.5;
  @media only screen and (max-width: 700px) {
    font-size: 4vw;
    margin: auto;
    flex-direction: column;
  }
  
}

h1 {
  font-family: 'Inter', sans-serif;

}

a:link {
  background-color: rgb(235, 236, 237);
  color: #1565c0;
  margin-right: 10px;
}
a:visited {
  background-color: rgb(235, 236, 237);
  color: #1976d2;
}
a:hover{
background-color: #b7e4c7
}


.App {
  text-align: left;
  color: #394045
}

.divider {
  border-bottom: 1px solid rgba(235, 236, 237, 0.8);
  padding-bottom: 20px;
  width: 100%;
  
}

.profilephoto {
width: 12rem;
height: 12rem;
border-radius: 50%;
object-fit: cover;


}
