@import url(https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{box-sizing:border-box}body{font-family:"Helvetica","Arial",sans-serif;font-size:18px;display:flex;margin:auto;padding:20px;max-width:700px;line-height:1.5}@media only screen and (max-width: 700px){body{font-size:4vw;margin:auto;flex-direction:column}}h1{font-family:"Inter",sans-serif}a:link{background-color:#ebeced;color:#1565c0;margin-right:10px}a:visited{background-color:#ebeced;color:#1976d2}a:hover{background-color:#b7e4c7}.App{text-align:left;color:#394045}.divider{border-bottom:1px solid rgba(235,236,237,.8);padding-bottom:20px;width:100%}.profilephoto{width:12rem;height:12rem;border-radius:50%;object-fit:cover}
.projects-list{display:flex;flex-direction:column;justify-content:center;align-items:center}a{font-weight:bold}
.project-screen{border:2px solid #f3f2f2;border-radius:10px 10px 0px 0px;overflow:hidden;width:80%;margin:20px 10px;box-shadow:0 0 10px rgba(0,0,0,.15);max-height:300px}.p-browser{height:20px;background-color:#f3f2f2;display:flex;align-items:center;position:-webkit-sticky;position:sticky;z-index:2}.p-circle{width:8px;height:8px;border-radius:50%;margin:3px}.dot-1{background-color:#fc6058}.dot-2{background-color:#fec02f}.dot-3{background-color:#2aca3e}.p-img{width:100%;transition:all 10s ease}.project-legend h3{text-transform:uppercase}.project-legend a{margin-right:10px}
.header h1{margin:15px 0px 0px}.header p{margin:0px 0px 30px;font-size:16px}.header span{margin-right:5px}.header-block{display:flex}@media only screen and (max-width: 700px){.header-block{flex-direction:column;align-items:center;margin:auto}}.header-links{margin-left:60px;font-size:14px}@media only screen and (max-width: 700px){.header-links{flex-direction:column;align-items:center;margin:auto}}.header-links>h2{margin-top:10px;margin-bottom:15px}
.tech-list{display:flex;flex-wrap:wrap;margin-top:20px}
.form-container{display:flex;flex-direction:column;align-items:center;justify-content:center;max-width:500px}form{display:flex;flex-direction:column;width:100%;background-color:#fff}input{display:flex;width:100%;height:50px;border:none;border-bottom:1px solid #000;margin:0px 0px;font-size:16px;padding-left:10px}textarea{margin:10px 0px;font-size:16px;padding-left:10px;font-family:Arial,Helvetica,sans-serif}::-webkit-input-placeholder{text-transform:uppercase}:-ms-input-placeholder{text-transform:uppercase}::placeholder{text-transform:uppercase}button{border:none;padding:15px;background-color:#59b256;color:#fff;font-weight:500;cursor:pointer;width:20%;height:10px;margin:10px 0px;display:flex;justify-content:center;align-items:center;border-radius:5px;text-transform:uppercase;font:13px Arial,sans-serif;font-weight:bold}
form{max-width:500px;margin:0 auto;display:grid;grid-template-columns:1fr;grid-gap:16px;gap:16px}.input-container{position:relative;display:flex;flex-direction:column}.input-container:focus-within label{-webkit-transform:translate(0, 12px) scale(0.8);transform:translate(0, 12px) scale(0.8);color:#0a53e4}.input-container .filled{-webkit-transform:translate(0, 12px) scale(0.8);transform:translate(0, 12px) scale(0.8)}.input-container label{position:absolute;pointer-events:none;-webkit-transform:translate(0, 23px) scale(1);transform:translate(0, 23px) scale(1);-webkit-transform-origin:top left;transform-origin:top left;transition:200ms cubic-bezier(0, 0, 0.2, 1) 0ms;color:#6f81a5;font-size:16px;line-height:1;left:16px;text-transform:uppercase}.input-container input{height:50px;border-radius:4px;border:none;padding:24px 16px 4px 16px;font-size:16px;line-height:1.2;outline:none;box-shadow:none;transition:200ms cubic-bezier(0, 0, 0.2, 1) 0ms;background-color:#e8e9ea;text-transform:lowercase}::-webkit-input-placeholder{text-transform:uppercase}:-ms-input-placeholder{text-transform:uppercase}::placeholder{text-transform:uppercase}.input-container input:focus{box-shadow:0 0 0 2px #79b1ff}
