.header {


 & h1{
    margin: 15px 0px 0px
 }

 & p {
     margin: 0px 0px 30px;
     font-size: 16px;

 }

 & span {
     margin-right: 5px;
 }
}



.header-block {
  display: flex;

  @media only screen and (max-width: 700px) {
   flex-direction: column;
   align-items: center;
   margin: auto;
  
  }
}
.header-links {
  margin-left: 60px;
  font-size: 14px;
    @media only screen and (max-width: 700px) {
   flex-direction: column;
   align-items: center;
   margin: auto;
  
  }

  & > h2 {
margin-top: 10px;
margin-bottom: 15px;

  }
}
