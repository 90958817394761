.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
}

input {
  display: flex;
  width: 100%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 0px 0px;
  font-size: 16px;
  padding-left: 10px;

}

textarea {
  margin: 10px 0px;
  font-size: 16px;
  padding-left: 10px;
  font-family: Arial, Helvetica, sans-serif;
}


::placeholder {
    text-transform: uppercase;


}
button {
  border: none;
  padding: 15px;
  background-color: #59b256;
  color: white;
  font-weight: 500;
  cursor: pointer;
  width: 20%;
  height: 10px;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
font: 13px Arial, sans-serif;
font-weight: bold;


  
}
